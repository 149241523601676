export default () => {
  Date.shortMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

  function addDays(e, t) {
    return e.setDate(e.getDate() + t), e;
  }

  function short_months(e) {
    return Date.shortMonths[e.getMonth()];
  }

  var count_date_el = document.getElementById("count-date-discount");
  if ("undefined" != typeof count_date_el && null != count_date_el)
    var today = new Date(),
      next_date_count = addDays(today, 1),
      current_year = next_date_count.getFullYear(),
      current_day = next_date_count.getDate(),
      str_next_day = short_months(next_date_count) + " " + current_day + ", " + current_year + " 00:00:00",
      countDownDate = new Date(str_next_day).getTime(),
      x = setInterval(function () {
        var e = new Date().getTime(),
          t = countDownDate - e,
          i = Math.floor(t / 864e5),
          n = Math.floor((t % 864e5) / 36e5),
          o = Math.floor((t % 36e5) / 6e4),
          s = Math.floor((t % 6e4) / 1e3);
        (document.getElementById("count-date-discount").innerHTML =
          '<span>' + i + '</span> : <span>' + n + '</span> : <span>' + o + '</span> : <span>' + s + '</span>')
        t < 0 && (clearInterval(x), (document.getElementById("count-date-discount").innerHTML = "EXPIRED"));
      }, 1e3);
}
