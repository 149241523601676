export default () => {
  $.fn.serializeJSON = function () {
    let o = {};
    let a = this.serializeArray();
    $.each(a, function () {
      if (o[this.name]) {
        if (!o[this.name].push) {
          o[this.name] = [o[this.name]];
        }
        o[this.name].push(this.value || '');
      } else {
        o[this.name] = this.value || '';
      }
    });
    return o;
  };

  let currentUrl = $('.js-current-url');
  if (currentUrl.length > 0) {
    currentUrl.val(location.href);
  }

  $(".js-google-form").submit(function (e) {
    e.preventDefault();

    // Submit form
    let form = $(this);
    let url = form.attr('action');
    let data = form.serializeJSON();

    $.ajax({
      type: "POST",
      dataType: "xml",
      url: url,
      data: data,
      xhrFields: {
        withCredentials: true
      },
      statusCode: {
        0: function () { // 0 is when Google gives a CORS error, don't worry it went through
          form.hide();
          form.prev().show();
          setTimeout(() => {
            window.location.href = '/cam-on';
          },2000);
        },
        200: function () { // 200 is a success code. it went through!
          form.hide();
          form.prev().show();
        },
        403: function () { // 403 is when something went wrong and the submission didn't go through
          console.log('Oh no! something went wrong. we should check our code to make sure everything matches with Google');
        }
      }
    });
  });
}
