import Swiper, {Pagination} from "swiper";

export default (el) => {
  const swiperEl = el.querySelector('.js-best-selling')

  new Swiper(swiperEl, {
    modules: [Pagination],
    slidesPerView: 'auto',
    spaceBetween: 8,
    autoHeight: true,
    loop: true,

    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      768: {
        slidesPerView: 3.3,
      },
      992: {
        slidesPerView: 4.3,
      },
      1200: {
        slidesPerView: 4.3,
        spaceBetween: 16,
        pagination: false,
      }
    },
  });
}
