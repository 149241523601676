const createTabContent = (childNodes) => {
  let divContent = document.createElement('div')

  divContent.classList.add('tab-content')

  childNodes.forEach(function (childNode, index) {
    childNode.classList.remove('d-none')
    childNode.classList.add('tab-pane', 'fade', 'border-top', 'border-2', 'border-primary', 'pt-3')

    divContent.appendChild(childNode)
  })

  return divContent
}

export default (el) => {
  const filterContents = el.querySelectorAll('.wpc-filter-content')
  const tabContents = createTabContent(filterContents)

  el.parentNode.insertBefore(tabContents, el.nextSibling)
}
