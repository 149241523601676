const createSearchIcon = () => {
  let spanIcon = document.createElement('span');
  spanIcon.classList.add('search-icon')
  spanIcon.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#f26b6f" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/></svg>`
  return spanIcon;
}

export default (el) => {
  const megaSearchEL = el.querySelector('.mega-search')
  const searchIcon = megaSearchEL.querySelector('.search-icon')
  const hasStickyEl = el.querySelectorAll('.mega-show-when-sticky')
  const catList = el.querySelector('.mega-menu-item-54961')
  const showMoreEl = catList.querySelector('.mega-sub-menu li:last-child')
  const newSearchIcon = createSearchIcon()

  searchIcon.replaceWith(newSearchIcon)

  $("ul#mega-menu-primary").on("mmm:showMobileMenu", function() {
    hasStickyEl.forEach((item) => {
      item.classList.remove('mega-show-when-sticky')
    })
  })

  // showMoreEl.addEventListener("click", (e) => {
  //   e.preventDefault()
  //   const textLinkEl = showMoreEl.querySelector('.mega-menu-link')
  //
  //   if (textLinkEl.innerHTML === 'Xem thêm...') {
  //     textLinkEl.innerHTML = 'Thu gọn...';
  //   } else {
  //     textLinkEl.innerHTML = 'Xem thêm...';
  //   }
  //
  //   hasStickyEl.forEach((item) => {
  //     item.classList.toggle('mega-show-when-sticky')
  //   })
  // })
}
