import Swiper, {Autoplay, Navigation, Thumbs} from "swiper";

export default () => {
  let swiperProductThumbs = new Swiper(".product-gallery-thumbs", {
    spaceBetween: 12,
    slidesPerView: 4,
    freeMode: true,
    watchSlidesProgress: true,
  });

  let swiperProductTop = new Swiper(".product-gallery-top", {
    modules: [Thumbs, Navigation, Autoplay],
    spaceBetween: 10,
    loop: true,
    loopedSlides: 6,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    thumbs: {
      swiper: swiperProductThumbs,
    },
  });

  $(document).on("mp_variations", function(e, data) {
    let {image_id} = data;
    let slideIndex = $("[data-image-id='" + image_id + "']").data('swiper-slide-index');

    swiperProductTop.slideTo(slideIndex);
  });
}
