export default () => {
  const modalVideo = $( '#modalVideo' )


  modalVideo.on('shown.bs.modal', function (e) {
    let src = $(e.relatedTarget).data('src')
    $(this).find('#video').attr('src', src + "?autoplay=1&amp;modestbranding=1&amp;showinfo=0")
  })

  // stop playing
  modalVideo.on( 'hide.bs.modal', function() {
    $(this).find('#video').attr( 'src', '' )
  } )
}
