import Swiper, {Autoplay, Navigation, Thumbs} from "swiper";

export default () => {
  let swiperThumbs = new Swiper(".gallery-thumbs", {
    slidesPerView: 1,
    freeMode: true,
    watchSlidesProgress: true,
    allowTouchMove: false,
    breakpoints: {
      576: {
        slidesPerView: 3,
        allowTouchMove: true,
      },
      992: {
        slidesPerView: 4,
        allowTouchMove: true,
      },
    }
  });

  let swiperTop = new Swiper(".gallery-top", {
    modules: [Thumbs, Navigation, Autoplay],
    spaceBetween: 10,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    thumbs: {
      swiper: swiperThumbs,
    },
  });
}
