const bars = document.getElementById('c-bars')
const elPrev = bars.previousSibling.previousSibling
const elHeight = bars.offsetHeight

function getBootstrapBreakpoint() {
  let w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  return (w < 768) ? 'xs' : ((w < 992) ? 'sm' : ((w < 1200) ? 'md' : 'lg'));
}

export default () => {
  let currentBreakpoint = getBootstrapBreakpoint()

  if (currentBreakpoint === 'xs') {
    elPrev.style.cssText = `padding-bottom:${elHeight}px`
  }
}
