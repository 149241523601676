import * as bootstrap from 'bootstrap';
import init from "./init";

/* eslint-disable */
__webpack_public_path__ = window.PUBLIC_PATH
/* eslint-enable */

init({
  module: 'modules'
}).mount()
