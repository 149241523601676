import Swiper, {Navigation} from "swiper";

export default (el) => {
  const swiperEl = el.querySelector('.js-category-promotion')

  new Swiper(swiperEl, {
    modules: [Navigation],
    slidesPerView: 1.2,
    spaceBetween: 12,
    autoHeight: true,
    loop: true,
    breakpoints: {
      768: {
        slidesPerView: 2.3,
        navigation: false
      },
      992: {
        slidesPerView: 3.3,
        navigation: false
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 16,
        navigation: {
          nextEl: ".category-promotion-next",
          prevEl: ".category-promotion-prev",
        },
      }
    },
  })
}
